















































































































































































import Vue from "vue";
import { ViewCandidates } from "@/interfaces/recruiter/candidates/view_candidates/view_candidates";
import ViewCandidatesBody from "@/components/recruiter/candidates/view_candidates/ViewCandidatesBody.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ADVANCE_SEARCH,
  ALL_CANDIDATES,
  FETCH_MATCHING_CANDIDATES,
  GET_FAVORITE_CANDIDATES,
  JOBS_POSTED_BY_RECRUITER,
  MATCHING_CANDIDATES,
  SEARCH_TEXT,
  TOP_JOBS_WITH_CANDIDATES,
  VIEW_CANDIDATES_DYNAMICALLY,
  VIEW_CANDIDATES_PAGINATION
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import {
  SearchCandidatesPayload,
  SearchedCandidate,
  ViewCandidatesPagination
} from "@/store/modules/recruiter/interfaces";
import AutoCompleteJobSearch from "@/components/shared/jobs/AutoCompleteJobSearch.vue";
import AutoCompleteUserSearch from "@/components/shared/AutoCompleteUserSearch.vue";
import AutoCompleteSkillSearch from "@/components/shared/jobs/AutoCompleteSkillSearch.vue";
import { SearchJobObject } from "@/interfaces/shared/jobs/auto_complete_job_search";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { GetFavoriteCandidates } from "@/interfaces/responses/favorite_candidates";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import SearchAnything from "@/components/recruiter/dashboard/SearchAnything.vue";

export default Vue.extend({
  /**
   * Activated this page
   *  1: User click on view more button (matching candidates) from dashboard
   *  2: User click on count number from job listing page
   *  3: User click on candidate tab from sidebar
   */
  name: "ViewCandidates",
  components: {
    ViewCandidatesBody,
    AutoCompleteSkillSearch,
    AutoCompleteJobSearch,
    AutoCompleteUserSearch,
    SearchAnything
    // JobsNotExist,
    // ViewCandidatesBody
  },
  data(): ViewCandidates & {
    searched_user_ids: number[];
  } {
    return {
      loading: true,
      dynamic_data: false,
      active_tab: "online",
      matching_candidates: [],
      job_with_candidate: null,
      advance_search_text: null,
      search_job_id: null,
      job_id: null,
      pagination: 1,
      user_ids: [],
      searched_user_ids: []
    };
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("recruiter", {
      view_candidates_dynamically: VIEW_CANDIDATES_DYNAMICALLY,
      get_matching_candidates: FETCH_MATCHING_CANDIDATES,
      top_posted_jobs: TOP_JOBS_WITH_CANDIDATES,
      get_all_candidates: ALL_CANDIDATES,
      view_candidates_pagination: VIEW_CANDIDATES_PAGINATION,
      get_search_text: SEARCH_TEXT
    })
  },
  async mounted() {
    const ids = sessionStorage.getItem("advance_search_user");
    if (!this.get_search_text) {
      this.searched_user_ids = [];
    }
    if (ids) {
      const array = ids.split(",").map((item: string) => parseInt(item));
      await this.fetch_advance_search_candidates(array);
      sessionStorage.removeItem("advance_search_user");
    } else {
      if (this.view_candidates_dynamically.active)
        await this.init_dynamically_data();
      else {
        await this.init_default_way_data();
      }
    }
    this.loading = false;
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    ...mapActions("recruiter", {
      fetch_matching_candidates: FETCH_MATCHING_CANDIDATES,
      fetch_top_jobs_with_candidates: JOBS_POSTED_BY_RECRUITER,
      fetch_matching_candidates_by_id: MATCHING_CANDIDATES,
      fetch_favorite_candidates_by_job: GET_FAVORITE_CANDIDATES,
      advance_search: ADVANCE_SEARCH
    }),
    ...mapMutations("recruiter", {
      set_candidates_view_dynamically: VIEW_CANDIDATES_DYNAMICALLY,
      set_all_candidates: ALL_CANDIDATES,
      set_view_candidates_pagination: VIEW_CANDIDATES_PAGINATION,
      set_search_text: SEARCH_TEXT
    }),
    update_candidates(tab: string) {
      switch (tab) {
        case "online":
          this.active_tab = "online";
          break;
        case "on site":
          this.active_tab = "on site";
          break;
      }
      if (this.job_with_candidate) {
        this.matching_candidates =
          this.job_with_candidate.matching_candidates.filter(
            (candidate: SearchedCandidate) =>
              candidate.availability.includes(this.active_tab)
          );
      } else {
        this.matching_candidates = this.filter_candidates_by_availability(
          this.get_all_candidates.results
        );
      }
    },
    async init_dynamically_data() {
      this.search_job_id = this.view_candidates_dynamically.job_id;
      this.user_ids = this.view_candidates_dynamically.user_ids || [];
      await this.fetch_candidates_with_pagination(
        this.view_candidates_pagination.pagination
      );
    },
    async init_default_way_data() {
      await this.fetch_candidates_with_pagination(
        this.view_candidates_pagination.pagination
      );
    },
    /**
     * Function to processing search skill filter
     */
    async searched_skill(value: string | null | undefined) {
      // If skill search filter is set & value isn't set yet => return
      if (
        this.view_candidates_pagination.searched_skill_filter &&
        value === undefined
      )
        return;
      // Update view candidate pagination payload
      this.set_view_candidates_pagination({
        pagination: this.view_candidates_pagination.pagination,
        searched_skill_filter: value ?? ""
      });
      // Update data set in order to view candidates
      this.set_candidates_view_dynamically({
        active: !!(
          value ||
          this.view_candidates_dynamically.job_id ||
          this.view_candidates_dynamically.user_ids?.length
        ),
        loading: false,
        job_id: this.view_candidates_dynamically.job_id,
        job_title: this.view_candidates_dynamically.job_title,
        skill: value ? value : "",
        user_ids: this.view_candidates_dynamically.user_ids || []
      });
      // If skill search filter reset => fetch all candidates (Optionally based on job if set)
      if (!value) {
        this.set_view_candidates_pagination({
          pagination: 1,
          searched_skill_filter:
            this.view_candidates_pagination.searched_skill_filter
        });
        await this.fetch_candidates_with_pagination(1);
      }
    },
    /**
     * Function to processing search job filter
     */
    async searched_job(value: SearchJobObject) {
      // Update local variable => hold the job id
      this.search_job_id = value ? value.job_id : null;
      // Update data set in order to view candidates
      this.set_candidates_view_dynamically({
        active: !!(
          value ||
          this.view_candidates_pagination.searched_skill_filter ||
          this.view_candidates_dynamically.user_ids?.length
        ),
        loading: false,
        job_id: value ? value.job_id : null,
        job_title: value ? value.text : "",
        skill: this.view_candidates_pagination.searched_skill_filter,
        user_ids: this.view_candidates_dynamically.user_ids || []
      });
      if (!value) {
        this.set_view_candidates_pagination({
          pagination: 1,
          searched_skill_filter:
            this.view_candidates_pagination.searched_skill_filter
        });
        await this.fetch_candidates_with_pagination(1);
      }
    },
    /**
     * Function to extract Ids from selected user
     */
    async searched_user(value: SearchUserObject[]) {
      this.user_ids = value?.map((item: SearchUserObject) => item.id);
      this.searched_user_ids = [];
      this.set_search_text("");
      // Update data set in order to view candidates
      this.set_candidates_view_dynamically({
        active: !!(
          value?.length ||
          this.view_candidates_pagination.searched_skill_filter ||
          this.view_candidates_dynamically.job_id
        ),
        loading: false,
        job_id: this.view_candidates_dynamically.job_id,
        job_title: this.view_candidates_dynamically.job_title,
        skill: this.view_candidates_pagination.searched_skill_filter,
        user_ids: this.user_ids
      });
      if (this.user_ids?.length === 0) {
        this.searched_user_ids = [];
        this.set_view_candidates_pagination({
          pagination: 1,
          searched_skill_filter:
            this.view_candidates_pagination.searched_skill_filter
        });
        await this.fetch_candidates_with_pagination(1);
      }
    },
    /**
     * FUnction to fetch top posted job data
     * For by default displaying in job autocomplete field
     */
    fetch_top_job_data(): SearchJobObject | null {
      if (this.top_posted_jobs.results?.length <= 0) return null;
      else if (
        this.view_candidates_dynamically.active &&
        this.view_candidates_dynamically.job_id
      ) {
        return {
          job_id: this.view_candidates_dynamically.job_id,
          text: this.view_candidates_dynamically.job_title
        };
      } else {
        return null;
      }
    },
    reset_data() {
      this.active_tab = "online";
      this.matching_candidates = [];
      this.advance_search_text = null;
      this.search_job_id = null;
      this.job_with_candidate = null;
      this.loading = false;
      this.pagination = 1;
      this.job_id = null;
      this.user_ids = [];
      this.searched_user_ids = [];
      this.set_search_text("");
    },
    /**
     * Function to handle filter form submit
     */
    async filter_form_submit() {
      this.loading = true;
      if (
        !this.advance_search_text &&
        !this.search_job_id &&
        !this.view_candidates_pagination.searched_skill_filter &&
        !this.user_ids?.length &&
        !this.get_search_text
      )
        return;
      if (this.get_search_text) {
        const response = await this.advance_search(this.get_search_text);
        if (response && response.data?.length) {
          this.user_ids = response.data;
          sessionStorage.setItem("advance_search_user", response.data);
        } else {
          this.user_ids = [];
          this.set_search_text("");
          this.root_error(response?.msg);
        }
      }
      this.set_view_candidates_pagination({
        pagination: 1,
        searched_skill_filter:
          this.view_candidates_pagination.searched_skill_filter
      });
      await this.fetch_candidates_with_pagination();
      this.loading = false;
    },
    async process_favorite_candidates(
      data: SearchedCandidate[],
      job_id: number
    ) {
      // Fetching favorite candidates against job
      const favorites = await this.fetch_favorite_candidates_by_job(job_id);
      const res = data.map((obj: SearchedCandidate) => ({
        ...obj,
        is_favorite: false
      }));
      // If favorite candidates found => process true if found
      if (favorites?.length > 0) {
        return res.map((obj1: SearchedCandidate) => {
          let obj2 = favorites.find(
            (obj2: GetFavoriteCandidates) =>
              obj1.candidate_id === obj2.candidate_id
          );
          if (obj2) {
            obj1.is_favorite = true;
          }
          return obj1;
        });
      } else return res;
    },
    /**
     * Function to return filtered candidates based on availability(online,on site)
     * @param candidates
     */
    filter_candidates_by_availability(
      candidates: SearchedCandidate[]
    ): SearchedCandidate[] {
      return candidates.filter((candidate: SearchedCandidate) =>
        candidate.availability.includes(this.active_tab)
      );
    },
    /**
     * Function to fetch candidates with pagination
     */
    async fetch_candidates_with_pagination(page = 1, limit = 12) {
      this.loading = true;
      const payload: SearchCandidatesPayload = {
        page: page - 1,
        limit_per_page: limit,
        n: 20,
        user_ids:
          this.searched_user_ids?.length > 0
            ? this.searched_user_ids
            : this.user_ids?.length > 0
            ? this.user_ids
            : []
      };
      // If job id is selected in filter => set else set null
      if (this.search_job_id) {
        payload.job_ids = [this.search_job_id];
        this.job_id = this.search_job_id;
      } else {
        this.job_id = null;
        this.job_with_candidate = null;
      }
      // If skill search filter is set => set skill name
      if (this.view_candidates_pagination.searched_skill_filter) {
        payload.skill_name =
          this.view_candidates_pagination.searched_skill_filter;
      }
      // Fetching all candidates
      const _all_candidates = await this.fetch_matching_candidates(payload);
      let all_candidates = _all_candidates;
      if (this.job_id) {
        // Set candidates whose score is greater than 20%
        all_candidates = _all_candidates.results.filter(
          (candidate: Candidates.Candidates) => candidate.score > 0.2
        );
      }
      // If candidates found
      if (
        (all_candidates &&
          all_candidates.results &&
          all_candidates.results?.length > 0) ||
        all_candidates?.length > 0
      ) {
        // Set filter count to -1 => if not set already
        if (!all_candidates.filtered_count) all_candidates.filtered_count = -1;
        this.set_all_candidates(all_candidates); // Setting in store
        // If job filter is applied
        if (this.job_id) {
          // Fetching favorite candidates
          const res = await this.process_favorite_candidates(
            this.job_id
              ? this.get_all_candidates
              : this.get_all_candidates.results,
            this.job_id
          );
          // Use to hold all candidates when job filter is applied
          this.job_with_candidate = {
            candidates_loading: false,
            matching_candidates: res
          };
          // Fetching matching candidates with favorites
          this.matching_candidates = this.filter_candidates_by_availability(
            this.job_with_candidate.matching_candidates
          );
        }
        // If job filter not applied
        else {
          this.matching_candidates = this.filter_candidates_by_availability(
            this.get_all_candidates.results
          );
        }
      }
      // If fetching all candidates failed
      else {
        this.root_error("No Candidates Found");
        this.set_all_candidates({ total: 0, results: [], filtered_count: -1 });
        this.reset_data();
        // If job filter is applied => reset
        if (this.search_job_id) {
          const ref = this.$refs.autocomplete_job as InstanceType<
            typeof AutoCompleteJobSearch
          >;
          ref.reset_state();
          this.search_job_id = null;
          this.job_id = null;
        }
        // If search filter is applied => reset
        if (this.view_candidates_pagination.searched_skill_filter) {
          const ref = this.$refs.autocomplete_skill as InstanceType<
            typeof AutoCompleteSkillSearch
          >;
          ref.reset_state();
          this.set_view_candidates_pagination({
            pagination: this.view_candidates_pagination.pagination,
            searched_skill_filter: ""
          });
        }
        // If users filter active
        if (this.user_ids?.length) {
          const ref = this.$refs.autocomplete_user as InstanceType<
            typeof AutoCompleteUserSearch
          >;
          ref.reset_state();
        }
        // If dynamic view set
        if (this.view_candidates_dynamically.active) {
          this.set_candidates_view_dynamically({
            active: false,
            loading: false,
            job_id: null,
            job_title: "",
            skill: "",
            user_ids: []
          });
        }
      }
      this.loading = false;
    },
    async fetch_advance_search_candidates(ids: number[]) {
      this.set_candidates_view_dynamically({
        active: true,
        loading: false,
        job_id: null,
        job_title: "",
        skill: "",
        user_ids: ids
      });
      this.user_ids = ids?.length > 0 ? ids : [];
      this.searched_user_ids = ids;
      await this.fetch_candidates_with_pagination(1);
    },
    async search_anything(text: string) {
      this.set_search_text(text); // set the input text in store
      if (!text) {
        // if user clear the input
        this.set_candidates_view_dynamically({
          active: true,
          loading: false,
          job_id: null,
          job_title: "",
          skill: "",
          user_ids: []
        }); // init the data
        this.searched_user_ids = [];
        this.user_ids = [];
        const payload: ViewCandidatesPagination = {
          pagination: 1,
          searched_skill_filter: ""
        };
        sessionStorage.removeItem("advance_search_user");
        this.set_view_candidates_pagination(payload); // rest pagination
        this.fetch_candidates_with_pagination(); // init candidates
      }
    }
  },
  beforeDestroy() {
    if (this.$route.name !== "candidate-profile") {
      const payload: ViewCandidatesPagination = {
        pagination: 1,
        searched_skill_filter: ""
      };
      this.set_view_candidates_pagination(payload);
    }
  }
});
